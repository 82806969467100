import Splide from '@splidejs/splide';

export default class SplideAutoMount extends HTMLDivElement {
    connectedCallback() {
        this.mountCarousel();
    }

    mountCarousel() {
        this.carousel = new Splide(this, 
        );

        this.carousel.mount();
    }
}
